<template>
  <v-flex class="pa-3" style="background: #e5e5e5;" xs12>
    <v-simple-table style="border: 1px solid #e2e2e2" dense>
      <template v-slot:default>
        <thead>
          <tr>
            <!-- <td class="font-weight-bold"> </td> -->
            <td class="font-weight-bold">#ID</td>
            <td class="font-weight-bold">NOME</td>
            <td class="font-weight-bold">PREÇO</td>
            <td class="font-weight-bold">DESCRIÇÃO</td>
            <td class="font-weight-bold">CATEGORIA</td>
            <!-- <td class="font-weight-bold">LOJA</td> -->
            <td class="font-weight-bold"></td>
          </tr>
        </thead>
        <tbody>
          <tr
            :style="
              `cursor: pointer; background: ${i % 2 === 0 ? '#f2f2f2' : '#fff'}`
            "
            v-for="(item, i) in get_produtos.docs"
            :key="item.id"
          >
            <!-- <td style="width: 10px"> <v-checkbox :color="$theme.primary" @click="setMarcado($event, item)" dense hide-details></v-checkbox> </td> -->
            <td
              style="width: 10px"
              @click="abre_modal_view_produto(item)"
              class="font-weight-bold"
            >
              {{ item.id_produto }}
            </td>
            <td @click="abre_modal_view_produto(item)">{{ item.nome }}</td>
            <td
              @click="abre_modal_view_produto(item)"
              class="green--text font-weight-bold fonte"
            >
              R$ {{ item.preco }}
            </td>
            <td @click="abre_modal_view_produto(item)">{{ item.descricao }}</td>
            <td @click="abre_modal_view_produto(item)">
              {{ item.categoria.nome }}
            </td>
            <!-- <td @click="abre_modal_view_produto(item)">
              {{ item.tenant.nome }}
            </td> -->
            <td style="width: 10px">
              <v-btn icon><v-icon> mdi-delete </v-icon></v-btn>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-flex>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["get_produtos"])
  },
  methods: {
    ...mapActions(["abre_modal_view_produto"]),
    setMarcado(event, item) {
      console.log("event", event);
      // this.add_produto_marcado(item)
    }
  }
};
</script>
